.accommodations {
  background-color: $secondary_color;
  color: $primary_color;
  padding-top: 2rems;
  padding-bottom: 6rem;

  @include size_S {
    padding-bottom: 4rem;
  }

  &_container {
    .slick-center {
      .offers_container_slider_container_title {
        @media (max-width: 801px) {
          opacity: 1;
        }
      }
    }

    .slick-dots li button:before {
      font-size: 1rem;
      top: 2rem;
      color: $primary_color;
    }

    &_slider {
      &_container {
        position: relative;
        padding: 0 1rem;

        @media (max-width: 801px) {
          padding: 0rem;
        }

        img {
          width: 100%;
          object-fit: cover;

          @media (max-width: 801px) {
            height: 300px;
          }
        }

        &_title {
          color: $secondary_color;

          @media (max-width: 801px) {
            opacity: 1;
          }

          h2 {
            @media (max-width: 801px) {
              font-size: 1.25rem;
              padding: 0 0.5rem;
              margin-bottom: 1rem;
            }
          }

          a {
            width: fit-content;
            position: relative;
            cursor: pointer;
            opacity: 1;

            @media (max-width: 801px) {
              font-size: 1rem;
            }

            &::after {
              content: '';
              position: absolute;
              bottom: -4px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: $secondary_color;
              opacity: 1;
              transition: opacity 300ms, transform 300ms;
              transform: scale(0);

              @media (max-width: 801px) {
                transform: scale(1);
              }

              transform-origin: center;
            }

            &:hover::after,
            &:focus::after {
              transform: scale(1);
            }
          }

          text-align: center;
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          left: 1rem;
          right: 1rem;

          @media (max-width: 801px) {
            left: 0;
            right: 0;
          }

          bottom: 0;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 0;
          transition: ease-in-out 250ms;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &_tiles {
      padding-top: 8rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      @include size_S {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
      }

      column-gap: 1rem;
      row-gap: 1rem;

      &_container {
        position: relative;

        img {
          display: flex;
          width: 100%;
          object-fit: cover;
        }

        &_title {
          color: $secondary_color;

          @media (max-width: 801px) {
            opacity: 1;
          }

          text-align: center;
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 0;
          transition: ease-in-out 250ms;

          &:hover {
            opacity: 1;
          }

          h2 {
            margin: 1rem;
            letter-spacing: 5px;
            font-size: 1.25rem;

            @include size_M {
              font-size: 0.75rem;
            }
          }

          a {
            width: fit-content;
            position: relative;
            cursor: pointer;
            opacity: 1;

            @media (max-width: 801px) {
              font-size: 1rem;
            }

            &::after {
              content: '';
              position: absolute;
              bottom: -4px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: $secondary_color;
              opacity: 1;
              transition: opacity 300ms, transform 300ms;
              transform: scale(0);

              @media (max-width: 801px) {
                transform: scale(1);
              }

              transform-origin: center;
            }

            &:hover::after,
            &:focus::after {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}
