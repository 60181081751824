.units {
  background-color: $secondary_color;
  color: $primary_color;
  padding-top: 3rem;
  padding-bottom: 2rem;

  @include size_S {
    padding-bottom: 0;
  }

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &_slick {
      margin-top: 2rem;
      display: flex;
      flex-direction: column-reverse;

      p {
        margin-bottom: 2rem;
      }

      &_title {
        text-align: center;
        margin-top: 2rem;
        text-align: center;
        height: 2rem;

        .slick-list {
          padding: 0 40px 0 40px;
        }

        .slick-prev {
          z-index: 1;
          left: 0;
          top: 8px;

          & svg {
            width: unset !important	;
          }

          &::before {
            content: unset;
          }
        }

        .slick-next {
          z-index: 1;
          right: 20px;
          top: 8px;

          &::before {
            content: unset;
          }
        }

        .slick-slide {
          opacity: 0.25;

          @include size_XS {
            div {
              padding: 0 25px;
            }
          }
        }

        .slick-center {
          font-weight: 600;
          color: $primary_color;
          opacity: 1;
          font-size: 1.5rem;

          @include size_M {
            font-size: 1.3rem;
          }

          transition: 250ms ease-in-out;
        }
      }

      &_container {
        position: relative;
        width: 100%;
        z-index: 1;

        &_image {
          width: 100%;
          max-height: 571px;
          padding-bottom: 90px;

          @include size_XS {
            max-height: 500px;
            padding-bottom: 0px;
          }

          object-fit: cover;
        }
      }
    }
  }

  .slick-list {
    overflow: unset;
    overflow-x: clip;
  }

  @include size_XS {
    .title_underline_complementary_small {
      &::after {
        display: none;
      }
    }
  }
}
