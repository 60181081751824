@font-face {
  font-family: 'Unist';
  src: local('Unist-Regular'),
    url('../assets/fonts/4_regular_extended_master1-webfont.woff2')
      format('woff2');
  font-display: swap;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --app-height: 100vh;
}

html {
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Unist';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
  height: 100svh;
  height: var(--app-height);
  transition: 500ms;
}

h1 {
  font-size: 1.5rem;

  @include size_S {
    font-size: 1.3rem;
  }

  font-weight: 400;
  text-align: center;
  margin-bottom: 4rem;
  letter-spacing: 8px;

  @include size_S {
    letter-spacing: 6px;
  }

  text-transform: uppercase;
}

h2 {
  font-size: 1.5rem;

  @include size_S {
    font-size: 1.3rem;
  }

  font-weight: 400;
  text-align: start;
  margin-bottom: 4rem;
  letter-spacing: 8px;

  @include size_S {
    letter-spacing: 4px;
  }

  text-transform: uppercase;
}

h3 {
  font-size: 1.25rem;
  font-weight: 500;

  @include size_S {
    font-size: 1.1rem;
  }
}

p {
  font-size: 1rem;
}

.text_center {
  text-align: center;
}

a {
  color: inherit;
  text-decoration: none;
  transition: $transition;
  cursor: pointer;
}

.body_wrapper {
  overflow-x: hidden;
}

.container {
  max-width: calc(1200px + 8rem);
  width: 100%;
  padding: 2rem 4rem;

  @include size_S {
    padding: 1rem 2rem;
  }

  margin: 0 auto;
}

.lock_scroll {
  overflow: hidden;
}

.opacity_transition_low {
  cursor: pointer;
  opacity: 1;

  &:hover {
    opacity: 0.5;
  }
}

.pointer {
  cursor: pointer;
}

.opacity_transition_high {
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.slick-slide div {
  outline: none;
}

.title_underline {
  &_light {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      bottom: -16px;
      left: 0px;
      height: 3px;
      width: 120px;
      background-color: $secondary_color;
    }

    &_small {
      position: relative;

      &::after {
        position: absolute;
        content: '';
        bottom: -8px;
        left: 0px;
        height: 2px;
        width: 100px;
        background-color: $secondary_color;
      }
    }
  }

  &_dark {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      bottom: -16px;
      left: 0px;
      height: 3px;
      width: 120px;
      background-color: $primary_color;
    }

    &_small {
      position: relative;

      &::after {
        position: absolute;
        content: '';
        bottom: -8px;
        left: 0px;
        height: 2px;
        width: 100px;
        background-color: $primary_color;
      }
    }
  }

  &_complementary {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      bottom: -16px;
      left: 0px;
      height: 3px;
      width: 120px;
      background-color: $complementary_color;
    }

    &_small {
      position: relative;

      &::after {
        position: absolute;
        content: '';
        bottom: -8px;
        left: 0px;
        height: 2px;
        width: 100px;
        background-color: $complementary_color;
      }
    }
  }
}

.button {
  display: flex;
  width: fit-content;
  margin: 2rem auto;
  color: $secondary_color;
  justify-content: flex-end;
  font-size: 1rem;
  font-weight: 600;

  div {
    display: flex;
    align-items: center;
    background-color: $primary_color;
    padding: 10px 20px;
    border-radius: 5px;
  }
}
