#hero_slideshow {
  height: 100%;
  overflow: hidden;

  .component {
    position: relative;

    &_overlay {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      color: $secondary_color;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        180deg,
        #{$secondary_color}+ '00' 50%,
        #{$primary_color}+ 'a3' 75%,
        $primary_color 100%
      );

      h2 {
        font-size: 3rem;

        @include size_L {
          font-size: 2rem;
        }

        @include size_S {
          font-size: 1.75rem;
        }

        text-align: center;
        margin-bottom: 2rem;
      }

      a {
        & div {
          background-color: $complementary_color;
        }
      }
    }
  }

  .slide {
    &_active,
    &_last {
      height: 100%;
      display: flex;
      img {
        height: 100%;
        width: 100vw;
        object-fit: cover;
      }
    }

    &_active {
      transition: transform 1s ease-in-out;
    }

    &_last {
      transition: unset;
    }
  }
}
