$transition: 250ms ease;
$transition_slow: 500ms ease;

$primary_color: #14397F;
$secondary_color: #FFFFFF;
$complementary_color: #2aa8e0;

:export {
    primaryColor: $primary_color;
    secondaryColor: $secondary_color;
    complementaryColor: $complementary_color;
}
